import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout"),
  },

  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // Continue to the login page
        next();
      },
    },
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/starter.vue"),
  },

  // Menus
  {
    path: "/settings/menus",
    name: "Menus",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/index"),
  },
  {
    path: "/settings/menus/form",
    name: "AddMenu",
    meta: { title: "Add Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },
  {
    path: "/settings/menus/form/:id",
    name: "EditMenu",
    meta: { title: "Edit Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },

  // Permissions
  {
    path: "/settings/permissions",
    name: "Permissions",
    meta: { title: "Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/index"),
  },
  {
    path: "/settings/permissions/form",
    name: "AddPermission",
    meta: { title: "Add Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },
  {
    path: "/settings/permissions/form/:id",
    name: "EditPermission",
    meta: { title: "Edit Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },

  // Roles
  {
    path: "/settings/roles",
    name: "Roles",
    meta: { title: "Role", authRequired: true },
    component: () => import("../views/user-management/roles/index"),
  },
  {
    path: "/settings/roles/:id",
    name: "ViewRole",
    meta: { title: "View Role", authRequired: true },
    component: () => import("../views/user-management/roles/info"),
  },
  {
    path: "/settings/roles/form",
    name: "AddRole",
    meta: { title: "Add Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },
  {
    path: "/settings/roles/form/:id",
    name: "EditRole",
    meta: { title: "Edit Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },

  // Modules
  {
    path: "/settings/modules",
    name: "Modules",
    meta: { title: "Module", authRequired: true },
    component: () => import("../views/user-management/modules/index"),
  },
  {
    path: "/settings/modules/form",
    name: "AddModule",
    meta: { title: "Add Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },
  {
    path: "/settings/modules/form/:id",
    name: "EditModule",
    meta: { title: "Edit Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },

  // Users
  {
    path: "/settings/users",
    name: "Users",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/index"),
  },
  {
    path: "/settings/users/:id",
    name: "UserProfile",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/profile"),
  },
  {
    path: "/users/profile/:id",
    name: "UserProfile2",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },
  {
    path: "/settings/users/form",
    name: "AddUser",
    meta: { title: "Add User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },
  {
    path: "/settings/users/form/:id",
    name: "EditUser",
    meta: { title: "Edit User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },

  {
    path: "/user/profile",
    name: "UserProfileView",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },

  // Operating Unit
  {
    path: "/maintenance/ou",
    name: "OperatingUnit",
    meta: { title: "Operating Unit", authRequired: true },
    component: () => import("../views/maintenance/ou"),
  },

  // Files

  // Projects
  {
    path: "/files/project",
    name: "Project",
    meta: { title: "Project", authRequired: true },
    component: () => import("../views/files/project"),
  },

  // Payment Scheme
  {
    path: "/files/unit",
    name: "Unit",
    meta: { title: "Unit", authRequired: true },
    component: () => import("../views/files/unit"),
  },

  // Payment Scheme
  {
    path: "/files/payment-scheme",
    name: "PaymentScheme",
    meta: { title: "Payment Scheme", authRequired: true },
    component: () => import("../views/files/payment-scheme"),
  },

  // Realty Firm
  {
    path: "/files/realty-firm",
    name: "RealtyFirm",
    meta: { title: "Realty Firm", authRequired: true },
    component: () => import("../views/files/realty-firm"),
  },

  // Agent
  {
    path: "/files/agent",
    name: "Agent",
    meta: { title: "Agent", authRequired: true },
    component: () => import("../views/files/agent"),
  },

  // Customer
  {
    path: "/files/customer",
    name: "Customer",
    meta: { title: "Customer", authRequired: true },
    component: () => import("../views/files/customer"),
  },

  // Maintenance

  // Location
  {
    path: "/maintenance/location",
    name: "Location",
    meta: { title: "Location", authRequired: true },
    component: () => import("../views/maintenance/location"),
  },

  // Unit Type
  {
    path: "/maintenance/unit-type",
    name: "UnitType",
    meta: { title: "Unit Type", authRequired: true },
    component: () => import("../views/maintenance/unit-type"),
  },

  // Designation
  {
    path: "/maintenance/designation",
    name: "Designation",
    meta: { title: "Designation", authRequired: true },
    component: () => import("../views/maintenance/designation"),
  },

  // File Employee Forms
  {
    path: "/files/employee/downloadable-form",
    name: "EmployeeDownloadableForms",
    meta: { title: "Employee Downloadable Forms", authRequired: true },
    component: () => import("../views/files/employee/downloadable-form"),
  },

  // File Employee Memos
  {
    path: "/files/employee/memos",
    name: "EmployeeMemos",
    meta: { title: "Employee Memos", authRequired: true },
    component: () => import("../views/files/employee/memos"),
  },

  // File Employee Payslip
  {
    path: "/files/employee/payslip",
    name: "EmployeePayslip",
    meta: { title: "Employee Payslip", authRequired: true },
    component: () => import("../views/files/employee/payslip"),
  },

  // Reservation Routes
  {
    path: "/reservation/record",
    name: "ReservationRecord",
    meta: { title: "Reservation Record", authRequired: true },
    component: () => import("../views/reservation"),
  },
];